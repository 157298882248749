<form [formGroup]="caseSummaryForm" (ngSubmit)="saveSummary()">
  <div class="full-width p-relative">
    <app-ruo-ivd-mapper>
      <ng-container IVD>
        <owc-textarea
          formControlName="summary"
          [maxLength]="fieldLengths.summaryField"
          rows="4"
          label="Report summary"
          i18n-label="CaseSummaryInput.Label@@report-summary"
          (inputKeyDown)="setCaseSummaryValue($event)"
        ></owc-textarea>
      </ng-container>
      <ng-container RUO>
        <owc-textarea
          formControlName="summary"
          [maxLength]="fieldLengths.summaryField"
          rows="4"
          label="Analysis report summary"
          i18n-label="CaseSummaryInput.Label@@analysis-report-summary"
          (inputKeyDown)="setCaseSummaryValue($event)"
        ></owc-textarea>
      </ng-container>
    </app-ruo-ivd-mapper>
    <div class="textarea-helper-texts">
      <owc-typography class="textarea-assistive-text" variant="caption" ngPreserveWhitespaces>
        {{ additionalHint }}
      </owc-typography>
    </div>
  </div>
  <div class="messages-container" ngPreserveWhitespaces>
    <save-error-message
      *ngIf="caseNoteError || (!additionalHint && caseCloseDisableError)"
      [saveError]="caseNoteError || caseCloseDisableError"
    ></save-error-message>
    <span
      *ngIf="saved && isSummarySaved$ | async"
      class="hint-message"
      i18n="CaseSummaryInput.Hint@@changes-saved"
    >
      changes saved
    </span>
  </div>
  <div slot="footer" class="footer-buttons">
    <owc-button
      type="button"
      variant="secondary"
      class="close-button"
      *ngIf="!caseSummary && isSummarySaved$ | async"
      (click)="closeSummary()"
    >
      <span i18n="Close|Button title@@close">Close</span>
    </owc-button>
    <owc-button
      type="submit"
      variant="primary"
      class="save-button"
      [disabled]="isCaseSaveBtnDisabled$ | async"
    >
      <span i18n="Save|Button title@@save">Save</span>
    </owc-button>
  </div>
</form>
