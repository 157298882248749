import { Component, OnInit, Input, OnDestroy, SimpleChanges, OnChanges } from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { FormControl } from '@angular/forms';
import { random as _random } from 'lodash';
import { ListItem } from 'app/model/entities/baseEntity';
import { Observable, Subject, of } from 'rxjs';
import { CommonService } from 'app/services/common.service';
import { takeUntil } from 'rxjs/operators';
import { Localization } from 'app/model/valueObjects/localization';

@Component({
  selector: 'app-autocomplete-input',
  templateUrl: './autocomplete-input.component.html',
  styleUrls: ['./autocomplete-input.component.scss']
})
export class AutocompleteInputComponent implements OnInit, OnDestroy, OnChanges {
  @Input() placeholder: string;
  @Input() options: ListItem[];
  @Input() defaultOption?: ListItem; // We revert to this value if user clears the text field.
  @Input() type?: string;
  @Input() data?: any;
  @Input() required?: boolean;
  @Input() autocompleteStyle?: string;
  @Input() autocompleteControl: FormControl;
  @Input() size?: string;
  fieldRequiredHint$: Observable<boolean>;
  CommonService = CommonService;

  // This is needed to set the name of the input to a random string, so that browser don't suggest auto fill.
  dateNow = _random(0, Number.MAX_SAFE_INTEGER).toString();

  private ngUnsubscribe = new Subject<void>();

  filteredOptions: any[];

  constructor(public commonService: CommonService, public localization: Localization) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options && changes.options.currentValue !== changes.options.previousValue) {
      this.initAutoComplete(this.options);
    }
  }

  ngOnInit(): void {
    this.fieldRequiredHint$ = this.getFieldRequiredHint$();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getFieldRequiredHint$(): Observable<boolean> {
    return of(CommonService.fieldRequiredHint(this.autocompleteControl));
  }

  togglePanel(event: Event, isOpen: boolean, trigger: MatAutocompleteTrigger): void {
    event.preventDefault();
    if (this.autocompleteControl.enabled) {
      if (isOpen) {
        trigger.closePanel();
      } else {
        trigger.openPanel();
      }
    }
    event.stopPropagation();
  }

  private initAutoComplete(options: any[]): void {
    CommonService.initAutoComplete(this.autocompleteControl, options)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((filteredList) => (this.filteredOptions = filteredList));

    // Set a value of null when given an empty string
    this.autocompleteControl.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((i) => {
      if (typeof i === 'string' && i.trim().length === 0) {
        this.autocompleteControl.setValue(this.defaultOption ?? null);
      }
    });
  }

  /**
   * Auto select the option if it's the ONLY available filtered option.
   */
  selectIfOnlySingleOption(trigger: MatAutocompleteTrigger): void {
    // Checking for string and not-null value to make sure user typed something to select.
    if (
      typeof this.autocompleteControl.value === 'string' &&
      this.autocompleteControl.value &&
      this.filteredOptions.length === 1 &&
      !this.filteredOptions[0].disabled
    ) {
      this.autocompleteControl.setValue(this.filteredOptions[0]);
      trigger.closePanel();
    }
  }
}
