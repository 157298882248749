<!-- "normal" template, used in standard angular material forms -->
<ng-container *ngIf="!autocompleteStyle">
  <mat-form-field  oneEditableDropdown oneEditableDropdownSize="{{size}}">
    <!--
      Note: appNoAutoComplete wasn't working here, hence applied autocomplete manually.
    -->
    <mat-label>{{placeholder}}</mat-label>
    <input class="{{placeholder}}" spellcheck="false" #trigger="matAutocompleteTrigger" (blur)="selectIfOnlySingleOption(trigger)"
      autocomplete="doNotAutoComplete" [name]="dateNow" type="text" matInput [matAutocomplete]="autoOptions" [formControl]="autocompleteControl"
      >
    <mat-hint *ngIf="required === true && type !== 'diagnosis' && autocompleteControl.enabled && fieldRequiredHint$ | async" class="cair-color-gray-400">
      <ng-container *ngIf="CommonService.fieldRequiredHint(autocompleteControl)">
        <span i18n="@@required-field-hint">This field is required</span>
        <span i18n="AutocompleteInput.Hint@@used-for-matching" *ngIf="type === 'gene'">. Used for matching.</span>
      </ng-container>
    </mat-hint>
    <mat-hint *ngIf="required === true && type === 'diagnosis' && fieldRequiredHint$ | async">
      <span i18n="AutocompleteInput.Hint@@this-field-is-required-select-assay-then-select-disease" *ngIf="CommonService.fieldRequiredHint(autocompleteControl)">This field is required. Select assay, then select disease</span>
    </mat-hint>
    <mat-hint *ngIf="type === 'subscriber' && data?.subscribedOn" class="cair-color-gray-400"><span i18n="AutocompleteInput.Hint@@subscribed-on">Subscribed on:</span> {{data.subscribedOn | dateFormat:localization.dateFormat}}</mat-hint>
    <app-error-icon *ngIf="autocompleteControl.invalid && autocompleteControl.touched" matSuffix></app-error-icon>
    <mat-error *ngIf="autocompleteControl.hasError('option'); else otherErrors"
              i18n="Must select from given options|Validation message for options selection@@validate-select-option">Must select from given options</mat-error>
    <ng-template #otherErrors>
      <mat-error *ngIf="type === 'subscriber' && autocompleteControl.hasError('repeated')" i18n="AutocompleteInput.Error@@cannot-repeat-the-same-email">Cannot repeat the same email</mat-error>
      <mat-error *ngIf="autocompleteControl.hasError('required') && type !== 'diagnosis'" i18n="@@required-field-validation">This field is required</mat-error>
      <mat-error *ngIf="autocompleteControl.hasError('required') && type === 'diagnosis' " i18n="AutocompleteInput.Error@@this-field-is-required-select-assay-then-select-disease">This field is required. Select assay, then select disease</mat-error>
      <mat-error
        *ngIf="autocompleteControl.hasError('assayUpdateRequired')"
        i18n="AutocompleteInput.Error@@current-assay-needs-to-be-updated"
      >The current assay selected needs to be updated. Check your assay configuration.</mat-error>
    </ng-template>
    <button
      (click)="togglePanel($event, autoOptions.isOpen, trigger)"
      [disabled]="autocompleteControl.disabled"
      mat-flat-button matSuffix one-icon-button-flat>
      <owc-icon class="cursor-pointer"
        [ngClass]="{'disabled': autocompleteControl.disabled}"
        [name]="autoOptions?.isOpen ? 'caret_up': 'caret_down'"
        type="filled">
      </owc-icon>
  </button>
  </mat-form-field>
  <mat-autocomplete autoActiveFirstOption #autoOptions="matAutocomplete" [displayWith]="commonService.displayOption" [ngSwitch]="type">
    <ng-container *ngSwitchCase="'diagnosis'">
      <ng-container *ngTemplateOutlet="diagnosis"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'changeDiagnosis'">
      <ng-container *ngTemplateOutlet="diagnosis"></ng-container>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <mat-option *ngFor="let option of filteredOptions" [value]="option" [attr.title]="option.value">{{option.value}}
      </mat-option>
    </ng-container>
    <ng-template #diagnosis>
      <mat-option [disabled]="option.disabled" [class.highlighted]="option.highlighted" [class.indent]="option.indent===1"
        [class.curated-option]="option.curatedContent" *ngFor="let option of filteredOptions" [value]="option"
        [attr.title]="option.value">
        <div class="option-name"><span *ngIf="option.curatedContent">* </span>{{option.value}}</div>
        <div *ngIf="option.curatedContent" class="curated-caption mat-caption"
          i18n="AutocompleteInput.DropdownOption@@curated-content-available">curated content available</div>
      </mat-option>
    </ng-template>
  </mat-autocomplete>
</ng-container>

<!-- "small" template, used in variant analytics -->
<div class="small" *ngIf="autocompleteStyle === 'small'">
  <mat-form-field oneEditableDropdown oneEditableDropdownSize="{{size}}">
    <mat-label>{{placeholder}}</mat-label>
    <input #trigger="matAutocompleteTrigger" (blur)="selectIfOnlySingleOption(trigger)" autocomplete="doNotAutoComplete"
      [name]="dateNow" spellcheck="false" type="text" matInput [matAutocomplete]="autoComplete"
      [formControl]="autocompleteControl" />
    <button
      (click)="togglePanel($event, autoComplete.isOpen, trigger)"
      [disabled]="autocompleteControl.disabled"
      mat-flat-button matSuffix one-icon-button-flat>
      <owc-icon class="cursor-pointer"
        [ngClass]="{'disabled': autocompleteControl.disabled}"
        [name]="autoOptions?.isOpen ? 'caret_up': 'caret_down'"
        type="filled">
      </owc-icon>
    </button>
  </mat-form-field>
  <mat-autocomplete autoActiveFirstOption #autoComplete="matAutocomplete" [displayWith]="commonService.displayOption">
    <mat-option *ngFor="let option of filteredOptions" [value]="option" [class.highlighted]="option.highlighted" [class.indent]="option.indent===1" [class.curated-option]="option.curatedContent" [attr.title]="option.value">
      <div class="option-name">
        <span *ngIf="option.curatedContent">* </span>
        {{ option.value }}
      </div>
      <div *ngIf="option.curatedContent" class="curated-caption cair-color-gray-400" i18n="AutocompleteInput.DropdownOption@@curated-content-available">curated content available</div>
    </mat-option>
  </mat-autocomplete>
</div>
