import { map, takeUntil } from 'rxjs/operators';
import { Component, EventEmitter, Input, OnInit, OnDestroy, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { CommonService } from 'app/services/common.service';
import { VariantService } from 'app/services/variant.service';

@Component({
  selector: 'app-case-summary-input',
  templateUrl: './case-summary-input.component.html',
  styleUrls: ['./case-summary-input.component.scss']
})
export class CaseSummaryInputComponent implements OnInit, OnDestroy {
  @Input() additionalHint: string;
  @Input() caseId: string;
  @Input() variantDetailMode = false;
  @Output() open = new EventEmitter<boolean>(true);

  private ngUnsubscribe = new Subject<void>();

  revision;
  caseSummaryForm: FormGroup;
  fieldLengths = CommonService.fieldLengths;
  caseNoteError = '';
  savedCaseNote = '';
  saved = false;
  caseUpdating = false;
  caseCloseDisableError = '';
  hasEditPermission = false;
  isSummarySaved$: Observable<boolean>;
  isCaseSaveBtnDisabled$: Observable<boolean>;

  get caseSummary(): string {
    return this.caseSummaryForm.get('summary').value;
  }

  set caseSummary(summary: string) {
    this.caseSummaryForm.get('summary').setValue(summary);
  }

  constructor(private fb: FormBuilder, private variantService: VariantService) {
    this.caseSummaryForm = this.fb.group({ summary: ['', []] });
  }

  ngOnInit(): void {
    this.variantService.caseNote.pipe(takeUntil(this.ngUnsubscribe)).subscribe((json) => {
      if (!json) {
        return;
      }

      this.revision = json['revision'] || 0;
      this.caseSummary = json['summary'] || '';
      this.savedCaseNote = json['summary'] || '';

      if (!this.variantDetailMode) {
        if (this.caseSummary) {
          this.open.emit(true);
        } else if (!this.saved) {
          this.open.emit(false);
        }
      }
      this.hasEditPermission = this.variantService.hasPermissionForCaseNote('createOrEditCaseNote');
    });

    this.variantService.caseNoteError.pipe(takeUntil(this.ngUnsubscribe)).subscribe((json) => {
      this.caseNoteError = json;
      if (this.caseNoteError && this.caseSummaryForm.get('summary').enabled) {
        this.caseSummaryForm.get('summary').disable();
      } else if (!this.caseNoteError && this.caseSummaryForm.get('summary').disabled) {
        this.caseSummaryForm.get('summary').enable();
      }
    });

    this.variantService.updatingCaseNote
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((json) => (this.caseUpdating = json));

    this.caseSummaryForm
      .get('summary')
      .valueChanges.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((json) => (this.caseCloseDisableError = ''));

    this.variantService.loadCaseNote(this.caseId);
    this.isSummarySaved$ = this.getIsSummarySaved$();
    this.isCaseSaveBtnDisabled$ = this.getIsCaseSaveBtnDisabled$();
  }

  isSummarySaved(): boolean {
    return this.caseSummary === this.savedCaseNote;
  }

  getIsSummarySaved$(): Observable<boolean> {
    return this.caseSummaryForm.get('summary').valueChanges.pipe(
      map((caseSummaryValue) => {
        return caseSummaryValue === this.savedCaseNote;
      })
    );
  }

  setCaseSummaryValue(event: CustomEvent<string>): void {
    this.caseSummary = event.detail;
  }

  getIsCaseSaveBtnDisabled$(): Observable<boolean> {
    return this.isSummarySaved$.pipe(
      map(
        (isSummarySaved) =>
          isSummarySaved ||
          this.caseNoteError !== '' ||
          this.caseUpdating ||
          !this.hasEditPermission
      )
    );
  }

  saveSummary(): void {
    const caseNoteBody =
      this.revision === 0
        ? { summary: this.caseSummary }
        : { revision: this.revision, summary: this.caseSummary };
    this.saved = true;
    this.variantService.updateCaseNote(this.caseId, caseNoteBody);
  }

  closeSummary(): void {
    this.open.emit(false);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
