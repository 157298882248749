import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { get as _get } from 'lodash';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { finalize } from 'rxjs/operators';
import { Diagnosis } from 'app/model/entities/case';
import { TmbMsiThresholdVersion } from 'app/model/valueObjects/tmbMsiThreshold';

@Injectable()
export class DiagnosisService {
  private _diagnosisByVersion = {};
  private _tmbMsiThresholdVersionByVersion = {};
  private _diagnosis = new BehaviorSubject<Diagnosis[]>([]);
  private _diagnosisLoading = new BehaviorSubject<boolean>(false);
  private _tmbMsiThresholdVersion = new BehaviorSubject<TmbMsiThresholdVersion>(null);

  constructor(private http: HttpClient, private authService: AuthService) {}

  get diagnosis(): Observable<Diagnosis[]> {
    return this._diagnosis.asObservable();
  }

  get tmbMsiThresholdVersion$(): Observable<TmbMsiThresholdVersion> {
    return this._tmbMsiThresholdVersion.asObservable();
  }

  get diagnosIsLoading() {
    return this._diagnosisLoading.asObservable();
  }

  loadDiagnosis(version: string) {
    if (!version || version === null) {
      this._diagnosis.next([]);
    } else if (this._diagnosisByVersion[version]) {
      this._diagnosis.next(this._diagnosisByVersion[version]);
      this._tmbMsiThresholdVersion.next(this._tmbMsiThresholdVersionByVersion[version]);
    } else {
      this._diagnosisLoading.next(true);
      return this.http
        .get<any>(this.authService.getURL('rocheContent', { version }))
        .pipe(finalize(() => this._diagnosisLoading.next(false)))
        .subscribe((json) => {
          this._diagnosisByVersion[version] = _get(json, 'diagnoses', []);
          this._diagnosis.next(this._diagnosisByVersion[version]);
          this._tmbMsiThresholdVersionByVersion[version] = _get(
            json,
            'tmbMsiThresholdVersion',
            null
          );
          this._tmbMsiThresholdVersion.next(this._tmbMsiThresholdVersionByVersion[version]);
        });
    }
  }
}
